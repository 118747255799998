<template>
  <div class="side-page">
    <div :class="['side-page__asside', { 'side-page__asside--shown' : assideVue }]">
      <vue-custom-scrollbar class="custom-scrollbar" :settings="{wheelPropagation: false}">
        <SidePanelModeration />
      </vue-custom-scrollbar>
    </div>
    <div class="side-page__main">
      <router-view/>
    </div>
  </div>
</template>

<script>

import Header from '@component/Part/Header';
import SidePanelCompany from '@component/Part/SidePanelModeration';
import SidePanelModeration from "@component/Part/SidePanelModeration";

export default {
  components: {
    // Header,
    SidePanelModeration,
  },
  data() {
    return {
      assideVue: false,
    };
  },
};
</script>

<style lang="scss" scoped>

// side-page
.side-page {
  display: flex;
  min-height: 100vh;
  background-color: #F2F2F6;
  .side-page__asside {
    position: fixed;
    left: 0;
    top: 0;
    width: 300px;
    height: 100%;
    padding-top: 52px;
    background-color: #FFFFFF;
    box-shadow: -3px 4px 12px rgba(0, 0, 0, 0.25);
    transform: translateX(-100%);
    transition: all .2s ease-in-out;
    z-index: 80;
    .custom-scrollbar {
      max-height: calc(100vh - 52px);
    }
    &--shown {
      transform: translateX(0);
    }
  }
  .side-page__main {
    width: 100%;
  }
  @include respond-to('sm') {
    .side-page__asside {
      width: 375px;
    }
  }
  @include respond-to('xl') {
    .side-page__asside {
      transform: translateX(0);
    }
    .side-page__main {
      padding-left: 375px;
    }
  }
}

</style>
