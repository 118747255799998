<template>
  <SideWrapper>
    <div class="sidebar">
      <div class="sidebar__top">
        <div class="sidebar__avatar-wrapp">
          <svg width="47" height="56" viewBox="0 0 47 56" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sidebar__avatar'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.502 0C15.829 0 9.586 6.243 9.586 13.916s6.243 13.916 13.916 13.916 13.916-6.243 13.916-13.916S31.175 0 23.502 0zM23.678 31.172h-.433c-2.075 0-4.123.295-6.089.861l6.346 9.813 6.339-9.802a22.227 22.227 0 00-6.163-.872zM45.513 49.871a21.974 21.974 0 00-12.468-16.628l-7.873 12.175V57h21.425l-1.084-7.13zM13.956 33.231A22.004 22.004 0 001.49 49.871L.406 57h21.426V45.41l-7.876-12.179z" fill="#C0C0CD"/></svg>
        </div>
        <div class="sidebar__title">
          <h4>Модератор</h4>
          <p>№ 4</p>
        </div>
      </div>
      <div class="sidebar__body">
        <a class="sidebar-link">
          <div class="sidebar-link__wrapp-icon">
            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sidebar-link__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.47 3.132L7.345.507a.878.878 0 00-.691 0L.529 3.132A.873.873 0 000 3.936v3.5c0 4.814 1.78 7.628 6.564 10.384a.878.878 0 00.872 0C12.22 15.07 14 12.257 14 7.436v-3.5a.872.872 0 00-.53-.804zm-3.16 3.976l-3.5 4.375a.884.884 0 01-.685.328h-.037a.878.878 0 01-.691-.389l-1.75-2.625a.874.874 0 111.456-.97l1.087 1.63 2.75-3.44a.875.875 0 011.368 1.092z" fill="#FF6B69"/></svg>
          </div>
          <p>Верификация данных</p>
        </a>
        <a class="sidebar-link sidebar-link--red">
          <div class="sidebar-link__wrapp-icon">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'sidebar-link__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M24.443 10.38l-2.924-.717a9.442 9.442 0 00-.616-1.494l1.495-2.49a.729.729 0 00-.103-.894l-2.08-2.08a.729.729 0 00-.894-.102l-2.49 1.494a9.434 9.434 0 00-1.494-.616L14.634.557A.752.752 0 0013.916 0h-2.93a.751.751 0 00-.717.557L9.565 3.48a9.434 9.434 0 00-1.494.616l-2.49-1.494a.729.729 0 00-.894.102l-2.08 2.08a.729.729 0 00-.102.894l1.494 2.49a9.433 9.433 0 00-.615 1.494l-2.827.718a.722.722 0 00-.557.703v2.93c0 .337.234.63.557.703l2.827.718c.16.512.366 1.01.615 1.494l-1.494 2.49a.729.729 0 00.102.893l2.08 2.08c.235.235.601.279.894.103l2.49-1.494c.484.249.982.454 1.494.615l.704 2.827c.087.323.38.557.717.557h2.93c.337 0 .63-.234.718-.557l.703-2.827a9.433 9.433 0 001.494-.615l2.49 1.494c.293.176.66.132.894-.102l2.08-2.08a.729.729 0 00.102-.894l-1.494-2.49c.25-.484.454-.982.616-1.494l2.924-.718a.722.722 0 00.557-.703v-2.93a.722.722 0 00-.557-.703zm-11.992 5.83A3.663 3.663 0 018.79 12.55a3.663 3.663 0 013.662-3.662 3.663 3.663 0 013.662 3.662 3.663 3.663 0 01-3.662 3.662z" fill="#FF6B69"/></svg>
          </div>
          <p>Техническая поддержка</p>
        </a>
        <p class="sidebar__subtitle">Список заявок:</p>
        <div class="nav-btn">
          <a class="nav-btn__link">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'nav-btn__icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.803 17.58h-8.04V5.192A2.265 2.265 0 0012.5 2.93a2.265 2.265 0 00-2.262 2.262V17.58h-8.04A2.2 2.2 0 000 19.777v.13a2.2 2.2 0 002.197 2.197h20.606A2.2 2.2 0 0025 19.907v-.13a2.2 2.2 0 00-2.197-2.197z" fill="#FF7A78"/><path d="M8.772 5.554c-.992.39-1.908.931-2.72 1.595v5.743a.732.732 0 11-1.465 0V8.615a10.16 10.16 0 00-2.296 6.442v1.058h6.481V5.554zM22.707 15.057a10.16 10.16 0 00-2.326-6.48v4.364a.732.732 0 11-1.465 0V7.124a10.214 10.214 0 00-2.69-1.57v10.56h6.482v-1.057z" fill="#FF7A78"/></svg>
            <span class="nav-btn__name">Заявки в работе</span>
          </a>
          <a class="nav-btn__link">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'nav-btn__icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.075 0H.925A.914.914 0 000 .904v12.84c0 .499.414.904.925.904h8.37l3.967 5.002a.933.933 0 001.03.301.905.905 0 00.626-.855v-4.448h2.157c.511 0 .925-.405.925-.904V.904c0-.5-.414-.904-.925-.904z" fill="#FF7A78"/></svg>
            <span class="nav-btn__name">Отклики</span>
          </a>
          <a class="nav-btn__link">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'nav-btn__icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 0C4.935 0 0 4.934 0 11c0 6.065 4.935 11 11 11s11-4.935 11-11c0-6.066-4.935-11-11-11zm6.316 9.142l-6.91 6.91a1.545 1.545 0 01-1.1.456c-.416 0-.806-.162-1.1-.455L4.684 12.53a1.546 1.546 0 01-.456-1.1c0-.416.162-.807.456-1.1a1.545 1.545 0 011.1-.456c.415 0 .806.162 1.1.456l2.422 2.422 5.81-5.811a1.545 1.545 0 011.1-.456 1.557 1.557 0 011.1 2.656z" fill="#FF7A78"/></svg>
            <span class="nav-btn__name">Выполненные</span>
          </a>
        </div>
  <!--        <NavBtn :list="navList" />-->

      </div>
    </div>
  </SideWrapper>
</template>

<script>
import SideAvatar from '@/component/Module/SidePanel/SideAvatar.vue';
import SideCollapse from '@/component/Module/SidePanel/SideCollapse.vue';
import SideOrdersAndIncome from '@/component/Module/SidePanel/SideOrdersAndIncome.vue';
import SideCompanyAttrs from '@/component/Module/SidePanel/SideCompanyAttrs.vue';
import SideWrapper from '@/component/Module/SidePanel/SideWrapper.vue';
// import NavBtn from "@component/Module/NavBtn";

export default {
  name: 'SidePanel',
  components: {
    // SideAvatar,
    // SideCollapse,
    // SideOrdersAndIncome,
    // SideCompanyAttrs,
    SideWrapper,
    // NavBtn,
  },
  data() {
    return {
      switchBtn: true,
    };
  },
};
</script>

<style lang="scss" scoped>

.sidebar {
  &__top {
    display: flex;
    align-items: center;
    padding: 30px 16px;
  }

  &__avatar-wrapp {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 70px;
    height: 70px;
    margin-right: 20px;
    background: #EDEDF4;
    border-radius: 50%;
    overflow: hidden;
  }

  &__avatar {
    width: 47px;
    height: 56px;
    object-fit: cover;

  }

  &__title {
    font-weight: 600;
    font-size: 19px;
    line-height: 22px;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    color: #757579;
    margin: 20px;
    text-align: center;
  }


  &-link {
    display: flex;
    align-items: center;
    font-size: 17px;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &-link--red {
    --color-prime: #FF6B69;
    --color-prime-active: #D7221F;
  }

  &-link__wrapp-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 10px;
  }

  &-link__icon {
    width: 18px;
    height: 18px;
  }

  .nav-btn {
    padding: 0 16px;
  }

  .nav-btn__link {
    justify-content: flex-start;
  }
}


// TODO nav-btn удалить после интеграции
.nav-btn {
  margin-bottom: 30px;
  .nav-btn__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 52px;
    margin-bottom: 10px;
    color: #000000;
    font-weight: 600;
    font-size: 17px;
    border-radius: 14px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 25px rgba(151, 161, 176, 0.25);
    transition: all .2s ease-in-out;
    cursor: pointer;
    &:before {
      content: "Показать категории заявок";
    }
    &:hover {
      box-shadow: 0 0 6px rgba(151, 161, 176, 0.25);
    }
  }
  .nav-btn__list {
    display: none;
  }
  .nav-btn__link {
    display: flex;
    align-items: center;
    height: 52px;
    margin-bottom: 10px;
    padding: 6px 20px;
    border-radius: 14px;
    border: 2px solid #FFFFFF;
    background-color: #FFFFFF;
    box-shadow: 0 3px 25px rgba(151, 161, 176, 0.25);
    transition: all .2s ease-in-out;
    cursor: pointer;
    .nav-btn__icon {
      margin-right: 12px;
      path {
        fill: var(--color-prime);
      }
    }
    .nav-btn__name {
      color: #000000;
      font-size: 17px;
      font-weight: 600;
    }
    &:hover {
      box-shadow: 0 3px 0 rgba(151, 161, 176, 0.25);
    }
    &.router-link-active {
      border: 2px solid var(--color-prime);
    }
  }
  &--shown {
    .nav-btn__toggle {
      color: #FFFFFF;
      background-color: var(--color-prime);
      box-shadow: 0 0 6px rgba(151, 161, 176, 0.25);
      &:before {
        content: "Свернуть категории заявок";
      }
    }
    .nav-btn__list {
      display: block;
    }
  }
  @include respond-to('sm') {
    .nav-btn__toggle {
      min-height: 56px;
      font-size: 19px;
    }
    .nav-btn__link {
      height: 56px;
      .nav-btn__name {
        font-size: 19px;
      }
    }
  }
  @include respond-to('md') {
    .nav-btn__list {
      display: flex;
      margin: 0 -10px;
    }
    .nav-btn__ell {
      margin-bottom: 0;
      padding: 0 10px;
    }
    .nav-btn__toggle {
      display: none;
    }
  }
}

</style>
